/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled, { useTheme } from 'styled-components'
import ParseContent from 'components/shared/ParseContent'

// Language
import { getLanguage } from 'services/language'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import GravityForm from 'components/GravityForm'

// Elements
import HeroDefault from 'components/elements/HeroDefault'
import BlockDefault from 'components/elements/BlockDefault'
// import ButtonSecondary from 'components/elements/ButtonSecondary'
import BackgroundDescription from 'components/elements/BackgroundDescription'
import TitleDefault from '../components/elements/TitleDefault'

const Left = styled.div`
  @media (min-width: 992px) {
    margin-top: -300px;
  }

  @media (max-width: 991px) {
    margin-top: -200px;
  }
`

const Right = styled.div`
  @media (min-width: 992px) {
    margin-top: -150px;
  }
`

const ContactBlock = styled(BlockDefault)`
  background-color: ${props => props.theme.color.contrast};
  color: ${props => props.theme.color.text.light};
`

// const ChatParseContent = styled(ParseContent)`
//   border-top: 1px solid ${props => props.theme.color.light};
//   font-size: ${props => props.theme.font.size.xm};
// `

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920)
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        form {
          title
          description
        }

        contact {
          description
          chat_description
          button_text
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  const language = getLanguage()

  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
      />

      <section>
        <HeroDefault small image={acf.banner.image} />
      </section>

      <section>
        <div className="container pb-5">
          <div className="row pb-5">
            <Left className="col-lg-6 mb-lg-5">
              <BlockDefault bigBorderRadius>
                <h1 className="mb-0" style={{ color: 'rgb(213, 16, 58)' }}>{acf.form.title}</h1>
                <div className="mb-3">
                  <ParseContent content={acf.form.description} />
                </div>
                <GravityForm id={language === 'nl_NL' ? 7 : 8} />
              </BlockDefault>
            </Left>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PageTemplate
